/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
$(".lazy").lazyload({
  effect : "fadeIn"
});
function resizeBlankImages() {
  $('.lazy').each(function () {
    var originalWidth = $(this).attr('data-width');
    var originalHeight = $(this).attr('data-height');
    var ratioH = originalWidth/originalHeight;
    var width = $(this).width();
    var height = width/ratioH;
    $(this).height(height);
  });

  $('.lazy.not-loaded').lazyload({
    effect: 'fadeIn', 
    threshold : 600,
    load: function() {
      $(this).removeClass("not-loaded");
      $(this).addClass("loaded");
    }
  });
  $('.card-title').removeClass("d-none");
  $('.card-text').removeClass("d-none");
}

resizeBlankImages();

$(window).on("resize",function(e){
  resizeBlankImages();
});
var buttonFilters = {};
var buttonFilter = '*';
var $grid = $('.grid').isotope({
  itemSelector : '.item',
  layoutMode : 'masonry',
  masonry: {
    columnWidth: '.item',
  },
    filter: function() {
    var $this = $(this);
    return $this.is( buttonFilter );
  }
});
$('.ajax_add_to_cart').on('click', function() {
  $grid.isotope('layout');
  setTimeout( function() {
    $grid.isotope('layout');
  }, 1000 );
});
$grid.on('layoutComplete', function(){
  $(window).trigger("scroll");
});
function concatValues( obj ) {
  var value = '';
  for ( var prop in obj ) {
    value += obj[ prop ];
  }
  return value;
}
$('.filters-select').on( 'change', function() {
  var $this = $(this);
  var filterValue = this.value;
  var $selectedValue = this;
  var filterGroup = $this.attr('data-filter-group');
  $this.find('.is-checked').removeClass('is-checked');
  $this.find(':selected').addClass('is-checked');
  buttonFilters[ filterGroup ] = filterValue;
  buttonFilter = concatValues( buttonFilters ) || '*';
  $grid.isotope();
});




var owloptions={
          nav:true,
          dots:false,
          items:1,
          loop:true,
          lazyLoad: true,
          lazyLoadEager: 1,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
           URLhashListener:true,
           startPosition: 'URLHash',
           navText:['<i class="fas fa-chevron-circle-left fa-lg"></i>','<i class="fas fa-chevron-circle-right fa-lg"></i>'],
        };
        $('.modal .owl-carousel').each(function() {
          var $this = $(this);
          $this.owlCarousel(owloptions);
        });

$('a[data-toggle="modal"]').click(function(){
    window.location.hash = $(this).attr('href');
});

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var owloptions={
          touchDrag:false,
          mouseDrag:false,
          pullDrag:false,
          freeDrag:false,
          nav:false,
          dots:false,
          items:1,
          loop:true,
          autoplay:true,
          autoplayTimeout:2000,
          lazyLoad: true,
          lazyLoadEager: 1
        };
        $('.home .owl-carousel').each(function() {
          var $this = $(this);
          $this.owlCarousel(owloptions);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
